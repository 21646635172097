<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Course List" slot="title" link="/helpContent/courseList" />
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
      :pagination="pagination"
    >
      <template slot="actionsLeft">
        <TableButton @click="() => add()">
          <img :src="require(`Assets/images/plus-1.png`)" />
          Add
        </TableButton>
        <TableButton @click="() => edit()">
          <img :src="require(`Assets/images/edit-1.png`)" />
          Edit
        </TableButton>
        <TableButton @click="() => generate()">
              <img :src="require(`Assets/images/PDF.png`)"/>
              Generate
          </TableButton>
        <!-- <TableButton @click="() => remove()">
              <img :src="require(`Assets/images/del.png`)"/>
              Delete
          </TableButton> -->
      </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import { getCourseList, removeRow,htmlToPdf } from "../api";
import tableConfig from "./table";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    // ValidationObserver,
  },
  data() {
    return {
      filtering: {
        id: 0,
      },
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
      listData: [],
      selectedRows: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  created() {
    this.getDataByFilter();
  },
  methods: {
    add() {
      this.$router.push({ path: "/course/add" });
    },

    edit() {
      if (this.validateSelectedLength(1)) {
        this.$router.push({
          path: `/course/edit/${btoa(this.selectedRows[0].Id)}`,
        });
      }
    },
    generate() {
      if (this.validateSelectedLength(1)) {
        var myWindow=window.open('','','width=700,height=900');
                myWindow.document.write(this.selectedRows[0].CourseHTML);
      // const data={
      //   html:this.selectedRows[0].CourseHTML,
      //   folderName:(this.selectedRows[0].ProgramName).replace(/\s/g,''),
      // }
      //   htmlToPdf(data).then((res)=>{
      //     window.open(res.data,'_blank' )
      //   });
      }
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },

    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    remove() {
      if (this.validateSelectedLength(1)) {
        if (this.deleteSelectedValue()) {
        }
      }
    },
    confirmDelete() {
      this.showWarningModal(false);
      const data = {
        id: this.selectedRows[0].Id,
      };
      removeRow(data).then((res) => {
        if (res.data[0].status > 1) {
          this.showWarningModal(true);
          this.selectedRows = [];
          this.warningModal.buttons.ok = true;
          this.warningModal.buttons.cancel = false;
          this.warningModal.buttons.confirm = false;
          this.warningModal.message = res.data[0].message;
        } else {
          this.selectedRows = [];
          this.warningModal.buttons.ok = true;
          this.warningModal.buttons.cancel = false;
          this.warningModal.buttons.confirm = false;
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
        }
      });
      this.getDataByFilter();
    },
    getDataByFilter() {
      setTimeout(() => {
        // this.$refs.validator.validate().then((result) => {
        //   if (result) {
        this.showLoader();
        const { key, order } = this.sorting;
        const { perPage, page } = this.pagination;

        const filters = {};
        filters.Id = 0;

        const data = {
          filterjson: {
            filter: [filters],
            sort: [{ key, order }],
            paging: [
              {
                startIndex: perPage * (page - 1),
                pagesize: perPage,
              },
            ],
          },
        };
        getCourseList(data).then(this.handleResponse).catch(this.handleError);
        //   }
        // });,
      }, 50);
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
      this.pagination.total = response.NumberOfRecords;
      this.buttonToShow = this.filtering.year;
    },
  },
};
</script>
