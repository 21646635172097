export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Course Name",
    key: "ProgramName",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Course Categories",
    key: "CourseCategories",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Course Code",
    key: "CourseCode",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Duration",
    key: "Duration",
    // sortable: true,
    minWidth: 80,
  },
 
];
